console.log("default dimensions running!");
export const default_dimensions = function(obj){


  let state = this;
  switch(state.type_str)
  {
    case "banner":
    case "banner_center":
      state.default_width = 100;
      state.default_height =  45;
    break;

    case "banner_edit":
      state.default_width = 200;
      state.default_height =  90;
    break;

    case "thumbnail":
    case "thumbnail_center":
       state.default_width = 50;
       state.default_height =  50;
    break;

    case "profile":
    case "profile_center":
       state.default_width = 100;
       state.default_height = 100;
    break;

    case "profile_edit":
    case "fit":
       state.default_width = 200;
       state.default_height = 200;
    break;

    case "image":
    case "image_center":
       state.default_width = 100;
       state.default_height = 75;
    break;

    case "none":
       state.default_width = 100;
       state.default_height = 100;
    break;

    case "fit_edit":
    default:
     state.default_width = 300;
     state.default_height = 300;
    break;

  }//end switch
}//end state.default_dimensions

// export default default_dimensions
