console.log(`add slider input running!`);

const slDat = require('./slide_data.js');
const imgUp = require('./image_update.js');
const cME = require('./canvas_mouse_events.js');
const gPP = require('./get_pointer_position.js');
const mMI = require('./move_my_image.js');


  export const add_slider_input = function(nbr)
  {
    let state = this;
    let slide_data = slDat.slide_data.bind(state);
    let image_update = imgUp.image_update.bind(state);
    let canvas_mouse_events = cME.canvas_mouse_events.bind(state);
    let getMousePos = gPP.getMousePos.bind(state);
    let getTouchPos = gPP.getTouchPos.bind(state);
    let get_touch_offset = gPP.get_touch_offset.bind(state);
    let move_my_image = mMI.move_my_image.bind(state);

    var home = document.getElementsByClassName("edit_slider_cont")[0];
    home.innerHTML = "";

          //reset slide input
  state.sli_ctrl_inputA = "";
  state.sli_ctrl_inputB = "";
  state.slide_limit = (state.image_object && state.image_object.naturalWidth) ? (state.image_object.naturalWidth/2) : state.slide_limit;

    var styleA = (nbr == 0 || nbr == 1) ? "goofy" : "default";//may need to make this different with html range slider
    var styleB = (nbr == 0 || nbr == 1) ? "default" : "goofy";//reverse for html slider
    var my_limit = (nbr == 0 || nbr == 1) ? state.slide_limit: state.canvas_width;//

    //SLIDER A
      var sli_ctrl_contA = document.createElement("div");
      sli_ctrl_contA.id = "sli_ctrl_contA";
      sli_ctrl_contA.className = "sli_ctrl_contA";//

        //input
        /*
          state.sli_ctrl_inputA = document.createElement("input");
          state.sli_ctrl_inputA.id = "sli_ctrl_inputA";
          state.sli_ctrl_inputA.className = "sli_ctrl_inputA";//
          state.sli_ctrl_inputA.setAttribute("data-slider-id","sli_ctrl_inputA");//
          state.sli_ctrl_inputA.setAttribute("data-slider-min","-" + my_limit);//
          state.sli_ctrl_inputA.setAttribute("data-slider-max",my_limit);//
          state.sli_ctrl_inputA.setAttribute("data-slider-step","1");//
          var set_valA = slide_data("A",nbr);
          var goof_A = set_valA * -1;//natural opposite effect
          var ctrl_valA = (style == "goofy") ? goof_A : set_valA;
          state.sli_ctrl_inputA.setAttribute("data-slider-value", ctrl_valA);//
          //state.sli_ctrl_inputA.setAttribute("data-slider-handle","custom");//ninja stars section
          state.sli_ctrl_inputA.type = "text";
          state.sli_ctrl_inputA.onfocus = function(){this.select();}
          */

          state.sli_ctrl_inputA = document.createElement("input");
          state.sli_ctrl_inputA.id = "sli_ctrl_inputA_" + state.iUN;
          state.sli_ctrl_inputA.className = "sli_ctrl_inputA sli_ctrl_inputA_" + state.iUN + " sli_ctrl_input slider";
          state.sli_ctrl_inputA.name = "sli_ctrl_inputA";
          //state.sli_ctrl_inputA.dataset.type = "range";
          state.sli_ctrl_inputA.type = "range";
          state.sli_ctrl_inputA.setAttribute("min","-" + my_limit);
          //max changes depending on user access
          //verified server side
          //entered range number can't be greater than db record of your max access
          state.sli_ctrl_inputA.setAttribute("max",my_limit);
          var set_valA = slide_data("A",nbr);
          var goof_A = set_valA * -1;//natural opposite effect
          var ctrl_valA = (styleA == "goofy") ? goof_A : set_valA;
          state.sli_ctrl_inputA.setAttribute("value",ctrl_valA);

      sli_ctrl_contA.appendChild(state.sli_ctrl_inputA);//

          //A onchange function below


          var sli_ctrl_boxA = document.createElement("input");
          sli_ctrl_boxA.id = "sli_ctrl_boxA";
          sli_ctrl_boxA.className = " sli_ctrl_boxA";//
          sli_ctrl_boxA.value = set_valA;//state.src_x;
          sli_ctrl_boxA.type = "number";
          sli_ctrl_boxA.onfocus = function(){this.select(); }

          sli_ctrl_boxA.oninput = function(){
          state.sli_ctrl_inputA.value = sli_ctrl_boxA.value;
            slide_data("A",nbr,{"value" :	sli_ctrl_boxA.value, "val_oper": "add"});
            //state.src_x = state.sli_ctrl_inputA.value;
            sliderA.setValue();
            state.draw_me();
          }//end on oninput



        //sli_ctrl_contA.appendChild(sli_ctrl_boxA);

    //END SLIDER A

    //SLIDER B
      var sli_ctrl_contB = document.createElement("div");
      sli_ctrl_contB.id = "sli_ctrl_contB";
      sli_ctrl_contB.className = "sli_ctrl_contB";

        //input
        /*
          state.sli_ctrl_inputB = document.createElement("input");
          state.sli_ctrl_inputB.id = "sli_ctrl_inputB";
          state.sli_ctrl_inputB.className = "sli_ctrl_inputB";//
          state.sli_ctrl_inputB.setAttribute("data-slider-id","sli_ctrl_inputB");//
          state.sli_ctrl_inputB.setAttribute("data-slider-min","-" + my_limit);
          state.sli_ctrl_inputB.setAttribute("data-slider-max",my_limit);//
          state.sli_ctrl_inputB.setAttribute("data-slider-step","1");//
          var set_valB = slide_data("B",nbr);
          var goof_B = set_valB * -1;//natural opposite effect
          var ctrl_valB = (style == "goofy") ? goof_B : set_valB;
          state.sli_ctrl_inputB.setAttribute("data-slider-value",ctrl_valB);
          state.sli_ctrl_inputB.setAttribute("data-slider-orientation","vertical");
          //state.sli_ctrl_inputB.setAttribute("data-slider-handle","custom");//ninja stars section
          state.sli_ctrl_inputB.type = "text";
          state.sli_ctrl_inputB.onfocus = function(){this.select();}
          */


          state.sli_ctrl_inputB = document.createElement("input");
          state.sli_ctrl_inputB.id = "sli_ctrl_inputB_" + state.iUN;
          state.sli_ctrl_inputB.className = "sli_ctrl_inputB sli_ctrl_inputB_" + state.iUN + " sli_ctrl_input slider";
          state.sli_ctrl_inputB.name = "sli_ctrl_inputB";
          //state.sli_ctrl_inputB.dataset.type = "range";
          state.sli_ctrl_inputB.type = "range";
          state.sli_ctrl_inputB.setAttribute("min","-" + my_limit);
          //max changes depending on user access
          //verified server side
          //entered range number can't be greater than db record of your max access
          state.sli_ctrl_inputB.setAttribute("max",my_limit);
          var set_valB = slide_data("B",nbr);
          var goof_B = set_valB * -1;//natural opposite effect
          var ctrl_valB = (styleB == "goofy") ? goof_B : set_valB;
          state.sli_ctrl_inputB.setAttribute("value",ctrl_valB);

      sli_ctrl_contB.appendChild(state.sli_ctrl_inputB);


          //console.info("sli_ctrl_inputB");//
          //console.dir(state.sli_ctrl_inputB);

          var sli_ctrl_boxB = document.createElement("input");
          sli_ctrl_boxB.id = "sli_ctrl_boxB";
          sli_ctrl_boxB.className = "sli_ctrl_boxB";//
          sli_ctrl_boxB.value = set_valB;//state.src_y;
          sli_ctrl_boxB.type = "number";
          sli_ctrl_boxB.onfocus = function(){this.select(); }

          //boxB input event
          sli_ctrl_boxB.oninput = function(){
            state.sli_ctrl_inputB.value = sli_ctrl_boxB.value;
            slide_data("B",nbr,{"value" : 	sli_ctrl_boxB.value, "val_oper": "add"});
            //state.src_y = state.sli_ctrl_inputB.value;
            sliderB.setValue();
            state.draw_me();
          }//end on oninput



        //sli_ctrl_contB.appendChild(sli_ctrl_boxB);

    home.appendChild(sli_ctrl_contA);
    home.appendChild(sli_ctrl_contB);

    /*
    var sliderA = new Slider('#state.sli_ctrl_inputA', {
      formatter: function(value) {
        return 'Current value: ' + value;
      }
    });//end new slider script

    console.info("sliderA");
    console.dir(sliderA);
    //http://seiyria.com/bootstrap-slider/

    var sliderB = new Slider('#state.sli_ctrl_inputB', {
      formatter: function(value) {
        return 'Current value: ' + value;
      }
    });//end new slider script
    */

    //$('input').slider();//calls both sliders
    //$('#state.sli_ctrl_inputA').slider();//
    //$("#lat_slider").on('change',function(){slideUpdate({'mode':gEd_mode,'dir':'lattitude'});});
    //$("#lon_slider").on('change',function(){slideUpdate({'mode':gEd_mode,'dir':'longitude'});});


        //$("#sli_ctrl_contB").on('change',function(){
        state.sli_ctrl_inputB.oninput = function(e){
          //console.log("slider B = ",state.sli_ctrl_inputB.value);
        image_update({"ltr":"B", "nbr":nbr, "style":styleB, "mode":"motion", "slide_el":state.sli_ctrl_inputB, "box_el":sli_ctrl_boxB});

        };//end on blur

        //A onchange function
        //$("#sli_ctrl_contA").on('change',function(){
        state.sli_ctrl_inputA.oninput = function(){
        //console.log("slider A = ",state.sli_ctrl_inputA.value);
        image_update({"ltr":"A", "nbr":nbr, "style":styleA, "mode":"motion", "slide_el":state.sli_ctrl_inputA, "box_el":sli_ctrl_boxA});

        };//end on blur//
    //http://seiyria.com/bootstrap-slider/

    //END SLIDER B

        canvas_mouse_events("set");

        state.canvas.onmouseover = function(e)
        {
          //
          canvas_mouse_events("set");

          window.onmousemove = function(e)
          {
            let option_nbr = nbr;
            move_my_image(e,{option_nbr,styleA,sli_ctrl_boxA,styleB,sli_ctrl_boxB});
          }//end state.canvas mousemove

        }//on mouse over

        //mouse has 4 events (mO,mD, mU, mM) touch only has 3 (tS,tE,tM)
        //so i had to do more with less - it was pretty straight forward after that.

        state.canvas.ontouchstart = function(e)
        {
          state.touchdown = true;
          e.preventDefault();
          get_touch_offset(e)
          canvas_mouse_events("set");
          //alert("touch started");

          state.canvas.ontouchmove = function(e)
          {
            //alert("move started");
            let option_nbr = nbr;
            move_my_image(e,{option_nbr,styleA,sli_ctrl_boxA,styleB,sli_ctrl_boxB});
          }//end state.canvas mousemove

        }//end ontouchstart

   //i put this in a separate function so i can use it for touch and mouse
   //state.canvas events





  }//end state.add_slider_input
