console.log('set view is running!');

export const setView = function(drawString)
{
  let state = this;
  state.drawData = "true";
  if(drawString.indexOf("{") == -1)
  {
    var drawString_ary = drawString.split(",");
    //state.scale = drawString_ary[9];
    //state.data_h = drawString_ary[10];


    state.img_url = unescape(drawString_ary[0]);

    //if(state.img_url == state.img_default){}

    state.src_x = drawString_ary[1];
    state.src_y = drawString_ary[2];
    state.img_w = drawString_ary[3];
    state.img_h = drawString_ary[4];
    state.can_x = drawString_ary[5];
    state.can_y = drawString_ary[6];
    state.can_w = drawString_ary[7];
    state.can_h = drawString_ary[8];
    state.canvas_width = drawString_ary[9];;
    state.canvas_height = drawString_ary[10];;


    //state.canvas_data = [escape(state.img_url), state.src_x, state.src_y, state.img_w, state.img_h, state.can_x, state.can_y, state.can_w, state.can_h, state.canvas_width, state.canvas_height,/*formerly bg_color*/, state.canvas_type].join();

    if(drawString_ary[11] != undefined && drawString_ary[11] != ""){
      state.bg_color = drawString_ary[11];
    }

    //[i think state.canvas_type == "match" is my answer to a universally sensitive masterImage object]
    //but so is running matchView()

    let match_it = (state.canvas_type == "match" || state.match_view == "true") ? true : false;
    if(drawString_ary[12] != undefined && drawString_ary[12] != "" && match_it){
      state.canvas_type = drawString_ary[12];
      state.type_str = (state.mode != "default") ? state.canvas_type + "_" + state.mode : state.canvas_type;
    }


  }else {
    let draw_obj = JSON.parse(drawString);
    state.scale = draw_obj.scale;
    //state.data_h = draw_obj.data_h;

    state.img_url = unescape(draw_obj.img_url);
    state.src_x = draw_obj.src_x;
    state.src_y = draw_obj.src_y;
    state.img_w = draw_obj.img_w;
    state.img_h = draw_obj.img_h;
    state.can_x = draw_obj.can_x;
    state.can_y = draw_obj.can_y;
    state.can_w = draw_obj.can_w;
    state.can_h = draw_obj.can_h;
    state.canvas_width = draw_obj.canvas_width;
    state.canvas_height = draw_obj.canvas_height;

    if(state.match_view == "true"){
      state.canvas_type = (draw_obj.type != "undefined" && draw_obj.type != "") ? draw_obj.type  : state.canvas_type;
      state.type_str = (state.mode != "default") ? state.canvas_type + "_" + state.mode : state.canvas_type;
      //why am i running default dimensions here? - why do i need it?
      default_dimensions({state});
    }//end if

  }//end else if array
  //state.initial_view = [state.img_url, state.src_x, state.src_y, state.img_w, state.img_h, state.can_x, state.can_y, state.can_w, state.can_h, state.canvas_width, state.canvas_height].join();
  state.initial_view = {"img_url":escape(state.img_url),"src_x":state.src_x, "src_y":state.src_y, "img_w":state.img_w, "img_h":state.img_h, "can_x":state.can_x, "can_y":state.can_y, "can_w":state.can_w, "can_h":state.can_h,"canvas_width":state.canvas_width,"canvas_height":state.canvas_height,"bg_color":state.bg_color,"type":state.canvas_type};
  state.import_data = "true";


}//end setView
