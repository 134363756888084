console.log(`create canvas running!`);

export const create_canvas = function(c_cont){

  let state = this;
  var bigDaddy = (document.getElementById(c_cont)) ? document.getElementById(c_cont) : document.getElementsByClassName(c_cont)[0];
   //clears container
   //console.log(`state.canvas c_cont = ${c_cont}`);
   if(state.clearHome == "true"){
     bigDaddy.innerHTML = "";//
   };

   /********************************  Sample Code  *****************************************

   ***************************************************************************************/

         //alert("data object is " + dataObject);
         //gets container


     var add_custom_class = (state.custom_class != "") ? state.custom_class  : "";
     var add_parent_class = (state.parent_class != "") ? state.parent_class  : "";

     var canvas_area = document.createElement("div");
     canvas_area.id = state.prefix + "_canvas_area" + state.iUN;
     canvas_area.className = state.prefix + "_canvas_area" + state.iUN + " " +  state.prefix + "_canvas_area"
     + " canvas_area " + " " + add_custom_class;

     canvas_area.addEventListener("click",function(e)
     {
       e.preventDefault();
       if(state.has_callout == "true")
       {
         var callout_fn = state.callout_params[0];
         callout_fn(e,this.id,state.callout_params[1],state.callout_params[2],state.callout_params[3],state.callout_params[4],state.callout_params[5]);
       }//end if
       if(state.stop_event_bubble == "true")
       {
         e.stopPropagation();
       }//if
       console.log("canvas area click entered");
     });

     state.canvas = document.createElement("canvas");
     state.canvas.id = state.prefix + "_ImgCanvas" + state.iUN;

     //state.event_ids.push(state.canvas.id);
     state.event_ids = [state.canvas.id];
     state.event_ids.push(canvas_area.id);

     state.canvas.className = state.prefix + "_ImgCanvas" + state.iUN + " " + state.prefix + "_ImgCanvas ImgCanvas responsive_canvas " + add_custom_class;

     if(state.fill_content != ""){state.canvas.innerHTML = state.fill_content;}

     canvas_area.appendChild(state.canvas);
     bigDaddy.appendChild(canvas_area);

     state.context_obj = state.canvas.getContext('2d');
     state.canvas.width = state.canvas_width;
     state.canvas.height = state.canvas_height;

     if(state.mode == "edit")
     {
       state.mini_canvas_area = document.createElement("div");
       state.mini_canvas_area.id = state.prefix + "_mini_canvas_area" + state.iUN;
       state.mini_canvas_area.className = state.prefix + "_mini_canvas_area" + state.iUN + " " + state.prefix + "_mini_canvas_area state.mini_canvas_area canvas_area responsive_canvas " + add_custom_class;

         //create a mini preview
         state.mini_canvas = document.createElement("canvas");
         state.mini_canvas.id = state.prefix + "_mini_canvas" + state.iUN;
         state.mini_canvas.className = state.prefix + "_mini_canvas" + state.iUN + " " + state.prefix + "_mini_canvas state.mini_canvas_area ImgCanvas responsive_canvas " + add_custom_class;

       state.mini_canvas_area.appendChild(state.mini_canvas);
       var mini_home = document.getElementsByClassName("edit_preview_box")[0];//will there ever be multiple? if so this won't work
       mini_home.appendChild(state.mini_canvas_area);

       switch(state.canvas_type)
       {
         case "profile":
           var mini_scale = .5;
         break;

         case "banner":
           var mini_scale = .75;
         break;

         default:
         var mini_scale = .5;
         break;
       }//end switch

       mini_home.style.width = state.canvas_width * mini_scale + "px";
       if(state.mode != "fit" && state.canvas_type != "fit"){mini_home.style.height = state.canvas_height * mini_scale + "px";}
       state.mini_context = state.mini_canvas.getContext('2d');


     }

     //set parents inline w,h
     var canvas_parent = state.canvas.parentNode;
     switch(state.type_str)
     {
       //is set like using this above
       //state.type_str = (state.mode != "default") ? state.canvas_type + "_" + state.mode : state.canvas_type;
       case "thumbnail":

       break;

       case "profile":
         //canvas_parent.className += (canvas_parent.className.indexOf("d3-profile-rounded") == -1) ? " d3-profile-rounded " : "";
         state.canvas.className += (state.canvas.className.indexOf("d3-profile-rounded") == -1) ? " d3-profile-rounded " : "";
         //console.log(canvas_parent.className);
       break;

       case "profile_edit":
         state.mini_canvas.className += (state.mini_canvas.className.indexOf("d3-profile-rounded") == -1) ? " d3-profile-rounded " : "";

       break;

       case "image":

       break;

       //this is used to show raw image
       //coresponding code : setRawDisplay & probably this entire switch (initially)
       case "profile_raw":
       case "thumbnail_raw":
       case "banner_raw":
         state.mode = "default";
         state.type_str = (state.mode != "default") ? state.canvas_type + "_" + state.mode : state.canvas_type;
       break;
     }//end switch

     canvas_parent.className += (canvas_parent.className.indexOf(add_parent_class) == -1) ? " " + add_parent_class + " " : "";
     //can_par.style.width = state.canvas_width;
     //can_par.style.height = state.canvas_width;


}//end create_canvas
