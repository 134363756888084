
console.log(`move my image running!`);
const gPP = require('./get_pointer_position.js');
const imgUp = require('./image_update.js');

  export const move_my_image = function(e,obj)
  {
    let state = this;
    let getMousePos = gPP.getMousePos.bind(state);
    let getTouchPos = gPP.getTouchPos.bind(state);
    let image_update = imgUp.image_update.bind(state);
    let nbr = obj.option_nbr;

    let styleA = obj.styleA;
    let sli_ctrl_boxA = obj.sli_ctrl_boxA;

    let styleB = obj.styleB;
    let sli_ctrl_boxB = obj.sli_ctrl_boxB;

   //console.log(e);
           if(state.mousedown == true || state.touchdown == true)
           {
             //alert("im goin in");
           //stops the mouse from hightlighting everyting on the page
           //e.preventDefault();
             var motion_method = (state.mousedown == true) ? "mouse" : (state.touchdown == true) ? "touch" : "error";
             //e.clientX,Y = mouse position in the browser window
             var y = (state.mousedown == true) ? e.clientY : e.touches[0].clientY;//this number is a large # like 400 or 600
             var x = (motion_method == "mouse") ? e.clientX : e.touches[0].clientX;
             var pos = (motion_method == "mouse") ? getMousePos(e) : getTouchPos(e);
             var pos_x = pos.x; //converts to a small number like 37
             var pos_y = pos.y;

             //if(motion_method == "touch"){alert("pos_x = " + pos_x + ", pos_y = " + pos_y
            // + ", x = " + x + ", y = " + y)}


             if(state.last_x != "default" && state.last_x != x)
             {
               //i use "default" initially so i can tell if the object
               //ever moved at all, if never used i set it in the else

               if(x < state.last_x)
               {
                 //then its going right
                 //this if helps me determine which way the mouse is moving

                 //console.log("x is less");
                 //calculate how far away the new mouse position is from the
                 //last mouse position
                 //var new_x = state.last_x - x;
                 //console.log("new_x = ",new_x);
                 //var cur_val_x = parseInt(state.sli_ctrl_inputA.value);
                 //state.sli_ctrl_inputA.value = cur_val_x -  new_x //pos_x;//cur_val_x - 50;

                 //what i need is to calculate how far img is from the
                 //point of origin basically the img_last_x  then i need to
                 //calculate how far the mouse is from that position and
                 //use it as an offset.


                 var cur_val_x = parseInt(state.sli_ctrl_inputA.value,10);
                 var new_x = pos_x + state.offset_x;
                 //console.log("new_x = ",new_x);
                 //console.log("styleA = ",styleA);
                 //state.sli_ctrl_inputA.value = new_x;



               }else
               {
                 //if it uses this section then its going left
                 //console.log("x is more");
                 //var new_x = x - state.last_x;
                 //console.log("new_x = ",new_x);
                 //var cur_val_x = parseInt(state.sli_ctrl_inputA.value);
                 //state.sli_ctrl_inputA.value = cur_val_x + new_x//pos_x//cur_val_x + 50;

                 var cur_val_x = parseInt(state.sli_ctrl_inputA.value,10);
                 var new_x = pos_x + state.offset_x;
                 //console.log("new_x = ",new_x);
                 //console.log("styleA = ",styleA);
                 //state.sli_ctrl_inputA.value =  new_x;

               }//end else x
               //console.log("new x val= ",state.sli_ctrl_inputA.value);
               if(nbr == 0)
               {
                 state.sli_ctrl_inputA.value =  new_x;
                 image_update({"ltr":"A", "nbr":nbr, "style":styleA, "mode":"motion", "slide_el":state.sli_ctrl_inputA, "box_el":sli_ctrl_boxA});//
               }else
               {
                 state.src_x = new_x * -1;
                 state.draw_me();
               }//end else


             }//end if state.last_x

             if(state.last_y != "default")
             {
               //what is important here is whether im above
               //or below the origin

               if(y < state.last_y && state.last_y != y)
               {
                 //then its going up
                 //console.log("y is less");
                 //var new_y = state.last_y - y;
                 //console.log("new_y = ",new_y);
                 //var cur_val_y = parseInt(state.sli_ctrl_inputB.value);
                 //state.sli_ctrl_inputB.value = cur_val_y - new_y//pos_y//poscur_val_y - 50;

                 //then its going up
                 var new_y = pos_y + state.offset_y;

                 //console.log("mm pos_y = ",pos_y);
                 //console.log("mm new_y = ",new_y);
                 //console.log("mm state.offset_y = ",state.offset_y);

                 //state.sli_ctrl_inputB.value = new_y;

               }else
               {
                 //console.log("y is more");
                 //var new_y =  y - state.last_y;
                 //console.log("new_y = ",new_y);
                 //var cur_val_y = parseInt(state.sli_ctrl_inputB.value);
                 //state.sli_ctrl_inputB.value = cur_val_y + new_y//pos_y//cur_val_y + 50;
                 //then its going up
                 //console.log("y is less");

                 //var cur_val_y = parseInt(state.sli_ctrl_inputB.value);
                 //var new_y = pos_y - cur_val_y;
                 var new_y = pos_y + state.offset_y;

                 //console.log("mm pos_y = ",pos_y);
                 //console.log("mm new_y = ",new_y);
                 //console.log("mm state.offset_y = ",state.offset_y);

                 //state.sli_ctrl_inputB.value = new_y;

               }//end else x



               //console.log("new input value = ",state.sli_ctrl_inputB.value);
               if(nbr == 0)
               {
                 state.sli_ctrl_inputB.value = new_y  * -1;
                 image_update({"ltr":"B", "nbr":nbr, "style":styleB, "mode":"motion", "slide_el":state.sli_ctrl_inputB, "box_el":sli_ctrl_boxB});
               }else
               {
                 //using this without modification creates
                 //and opposite effect during moving.
                 //state.src_y = new_y;

                 //modify with new_y * -1
                 state.src_y = new_y * -1;

                 state.draw_me();
               }//end else
             }


             // i use this the clear the default word and to set the
             //last move for each subsequent mousemove after it runs the
             //above processes
             state.last_x = x;
             state.last_y = y;
             //console.log("mouse-x = ",x);
             //console.log("last_x = ",state.last_x);
             //console.log("pos_x = ",pos_x);
             //console.log("pos_y = ",pos_y);

           }else
           {
             //clear the tracker
             //state.last_x = "";
             //state.last_y = "";
           }
           //end if

   }//end move my image
