console.log(`control panel running!`);
const aryJs = require('./arrays.js');
const rC = require('./run_contents.js');

export const control_panel = function(){

  let state = this;
  //object properties
  let run_contents = rC.run_contents.bind(state);

  let size_ary = aryJs.size_ary;
  let ctrl_ary = aryJs.ctrl_ary;

  //local variables

  //jqm collapsible
  var bigDaddy = document.getElementsByClassName(state.home)[0];
  //clear the container
  bigDaddy.innerHTML = "";

  var edit_box = document.createElement("div");
  edit_box.id = "edit_box" + state.iUN;
  edit_box.className = "edit_box" + state.iUN + " edit_box ";//test_orange
  //collapsible set


  //make the other Stuff

  var edit_enclosure = document.createElement("div");
  edit_enclosure.id = "edit_enclosure" + state.iUN;
  edit_enclosure.className = "edit_enclosure" + state.iUN + " edit_enclosure ";//test_blue

  //edit_sectionB
  var edit_sectionB = document.createElement("div");
  edit_sectionB.id = "edit_sectionB" + state.iUN;
  edit_sectionB.className = "edit_sectionB" + state.iUN + " edit_sectionB ";//test_blue

    var add_custom_class = (state.custom_class != "") ? state.custom_class  : "";
  //canvas_cont
    var image_display = document.createElement("div");
    image_display.id = "image_display" + state.iUN;
    image_display.className = "image_display" + state.iUN + " image_display ";//test_purple canvas_wrapper

          //canvas_cont
          var canvas_cont = document.createElement("div");
          canvas_cont.id = "canvas_cont" + state.iUN;
          canvas_cont.className = "canvas_cont" + state.iUN + " canvas_cont " + " " + add_custom_class;//test_purple
          state.obj_els["edit_home_id"] = canvas_cont.id;

      image_display.appendChild(canvas_cont);

      //edit_slider_box
      var edit_slider_box = document.createElement("div");
      edit_slider_box.id = "edit_slider_box" + state.iUN;
      edit_slider_box.className = "edit_slider_box" + state.iUN + " edit_slider_box ";//test_yellow

          //edit_lock_box
          var edit_lock_box = document.createElement("div");
          edit_lock_box.id = "edit_lock_box" + state.iUN;
          edit_lock_box.className = "edit_lock_box" + state.iUN + " edit_lock_box test_pink";

                    //edit_lock_box
          var edit_slider_cont = document.createElement("div");
          edit_slider_cont.id = "edit_slider_cont" + state.iUN;
          edit_slider_cont.className = "edit_slider_cont" + state.iUN + " edit_slider_cont ";//test_green


      edit_slider_box.appendChild(edit_slider_cont);
      edit_slider_box.appendChild(edit_lock_box);



                          //edit_resize_box
      var edit_resize_box = document.createElement("div");
      edit_resize_box.id = "edit_resize_box" + state.iUN;
      edit_resize_box.className = "edit_resize_box" + state.iUN + " edit_resize_box ";


  //$(".ctrl_cont").addClass("hibernate");
  //$(".col_label").removeClass("hide");
  edit_sectionB.appendChild(edit_resize_box);
  edit_sectionB.appendChild(image_display);
  edit_sectionB.appendChild(edit_slider_box);

  var edit_clr = document.createElement("div");
  edit_clr.id = "edit_clr" + state.iUN;
  edit_clr.className = "edit_clr" + state.iUN + " edit_clr clr";


  edit_enclosure.appendChild(edit_sectionB);
  edit_enclosure.appendChild(edit_clr);
  edit_box.appendChild(edit_enclosure);

  //edit_cmd_label
      var edit_xtra_ctrls = document.createElement("div");
      edit_xtra_ctrls.id = "edit_xtra_ctrls" + state.iUN;
      edit_xtra_ctrls.className = "edit_xtra_ctrls" + state.iUN + " edit_xtra_ctrls " + state.show_adv;
                    //edit_cmd_label
          var edit_cmd_label = document.createElement("div");
          edit_cmd_label.id = "edit_cmd_label" + state.iUN;
          edit_cmd_label.className = "edit_cmd_label" + state.iUN + " edit_cmd_label";


          var ctrl_box = document.createElement("div");
          ctrl_box.id = "ctrl_box" + state.iUN;
          ctrl_box.className = "ctrl_box" + state.iUN + " ctrl_box edit_sectionA ";



  edit_xtra_ctrls.appendChild(ctrl_box);
  edit_xtra_ctrls.appendChild(edit_cmd_label);
  edit_box.appendChild(edit_xtra_ctrls);

  var edit_adv = document.createElement("div");
  edit_adv.id = "edit_adv" + state.iUN;
  edit_adv.className = "edit_adv" + state.iUN + " edit_adv test_green";


  var edit_preview = document.createElement("div");
  edit_preview.id = "edit_preview" + state.iUN;
  edit_preview.className = "edit_preview" + state.iUN + " edit_preview";// canvas_wrapper test_green

  var edit_preview_box = document.createElement("div");
  edit_preview_box.id = "edit_preview_box" + state.iUN;
  edit_preview_box.className = "edit_preview_box" + state.iUN + " edit_preview_box canvas_cont tes_purple";

  edit_preview.appendChild(edit_preview_box);

  edit_box.appendChild(edit_adv);
  edit_box.appendChild(edit_preview);

  bigDaddy.appendChild(edit_box);




  var test_nbr = 3;
  //content for ctrl_box
  for(var x = 0; x < ctrl_ary.length ; x++){

    var ec_Nm = "edit_ctrl_btn" + x;
    var edit_ctrl_btn = document.createElement(ctrl_ary[x].type);
    edit_ctrl_btn.id = "edit_ctrl_btn" + state.iUN + "_" + x;
    edit_ctrl_btn.className = "edit_ctrl_btn" + state.iUN + "_"  + x + " edit_ctrl_btn" + x + " edit_ctrl_btn " + ctrl_ary[x].class;
    switch(ctrl_ary[x].type){
      case"button":
        edit_ctrl_btn.setAttribute("href","#");
      break;
      case "input":
        edit_ctrl_btn.setAttribute("type","color");
        edit_ctrl_btn.setAttribute("value","#ffffff");
      break;
    }//end switch

    edit_ctrl_btn.dataset.nbr = x;
    edit_ctrl_btn.dataset.contents = ctrl_ary[x].contents;
    edit_ctrl_btn.title = ctrl_ary[x].title;
    //edit_ctrl_btn.innerHTML = "<h5>" + ctrl_ary[x].label + "</h5>";
    state.obj_els[ec_Nm] = edit_ctrl_btn;

    //helps set up the correct call inside the event listener
    state.obj_els["contents" + x] = ctrl_ary[x].contents;

    var click_action = (ctrl_ary[x].type != "input") ? "click" : "change";
      state.obj_els[ec_Nm].addEventListener(click_action,function(e){
        //i used this.dataset so it doesn't pass the updated x of the for loop
        //and everything ending up being on click of the last index nbr passed
        e.preventDefault();
        var sNbr = this.dataset.nbr;
        var my_contents = this.dataset.contents
        run_contents(my_contents);
        ctrl_txt(this.title);
      })//end c_Nm


   ctrl_box.appendChild(edit_ctrl_btn);

  }//end for


  //content for edit_resize_box
   for(var y = 0; y < size_ary.length ; y++){

    var er_Nm = "zoom_ctrls" + y;
    var zoom_ctrls = document.createElement("button");
    zoom_ctrls.id = size_ary[y].id + state.iUN + "_" + y;
    zoom_ctrls.className = size_ary[y].id + state.iUN + "_"   + y + " " + size_ary[y].id + y
    + " " + size_ary[y].class + " " ;
    zoom_ctrls.setAttribute("href","#");
    zoom_ctrls.dataset.nbr = y;
     zoom_ctrls.dataset.contents = size_ary[y].contents;
    zoom_ctrls.title = size_ary[y].title;
    //zoom_ctrls.innerHTML = "" + size_ary[y].label + "";
    state.obj_els[er_Nm] = zoom_ctrls;

    if(size_ary[y].id == "reset_image" && state.import_data != "true")
    {
      //disable btn if not from saved data
      zoom_ctrls.setAttribute("disabled","true");
      zoom_ctrls.className = size_ary[y].id + state.iUN + "_"   + y + " " + size_ary[y].id + y
      + " " + " reset_image zoom_ctrls d3-ui d3-icon-reset_lt ";//changes to the correct btn display
    }

    //helps set up the correct call inside the event listener
    //i dont think this works (think thats why i used dataset)
    state.obj_els["contents" + y] = size_ary[y].contents;


    state.obj_els[er_Nm].addEventListener("click",function(e){
      //i used this.dataset so it doesn't pass the updated x of the for loop
      //and everything ending up being on click of the last index nbr passed
      e.preventDefault();
      var sNbr = this.dataset.nbr;
      var my_contents = this.dataset.contents
      run_contents(my_contents);
      ctrl_txt(this.title);
    })//end c_Nm

   edit_resize_box.appendChild(zoom_ctrls);

  }//end for

  state.create_canvas(state.obj_els["edit_home_id"]);
  run_contents("IP");

}//end state.control_panel

var ctrl_txt = function(txt)
{
  document.getElementsByClassName("edit_cmd_label")[0].innerHTML = "<h5>" + txt + "</h5>";
}//end ctrl_txt
