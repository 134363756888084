console.log(`update slide limit running!`);

export const update_slide_limits = function (id,calc)
{
  let state = this;


  let slide_str = `.${id}_${state.iUN}`
  let slider_el = document.querySelector(slide_str);
  slider_el['min'] = -1 * (calc/2);
  slider_el['max'] = (calc/2);
}// update_slide_limits
