
console.log('image_update running!');

const slDat = require('./slide_data.js');

  export const image_update = function(mObj){
  //console.info("image update running");

  let state = this;
  let slide_data = slDat.slide_data.bind(state);


  var style = mObj.style;
  var ltr = mObj.ltr;

  var nbr = mObj.nbr;
  var mode = mObj.mode;//"motion" or not ("input" as other)

  var slide_el = mObj.slide_el;
  var box_el = mObj.box_el;

  var target_el = (mode == "motion") ? slide_el : box_el;

  if(mode == "motion")
  {
    //make regular and goofy foot (opposite) values
    var val_regular_input =  target_el.value;
    var val_goof_input = target_el.value * -1;
    var input_val = (style == "goofy") ? val_goof_input : val_regular_input;
    box_el.value = input_val;//unique to motion
  }else
  {
    if(ltr = "A"){
      state.sli_ctrl_inputA.value = box_el.value;
    }else{
      state.sli_ctrl_inputB.value = box_el.value;
    }

    var input_val = box_el.value;
    //slide_data("B",nbr,{"value" : 	sli_ctrl_boxB.value, "val_oper": "add"});
    //state.src_y = state.sli_ctrl_inputB.value;

  }

  slide_data(ltr,nbr,{"value" :	input_val, "val_oper": "add"});


  if(mode == "input"){
    if(ltr == "A")
    {
      //make these "object properties"
      sliderA.setValue();//unique to input
    }else
    {
      sliderB.setValue();//unique to input
    }
  }//end if mode ==  input

  state.draw_me();

  }//end image_update
