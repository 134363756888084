console.log(`create image running!`);

export const create_image = function(c_cont){

  let state = this;
  var bigDaddy = (document.getElementById(c_cont)) ? document.getElementById(c_cont) : document.getElementsByClassName(c_cont)[0];
   //clears container
   //console.log(`state.canvas c_cont = ${c_cont}`);
   if(state.clearHome == "true"){
     bigDaddy.innerHTML = "";//
   };

   /********************************  Sample Code  *****************************************

   ***************************************************************************************/

         //alert("data object is " + dataObject);
         //gets container


     var add_custom_class = (state.custom_class != "") ? state.custom_class  : "";
     var add_parent_class = (state.parent_class != "") ? state.parent_class  : "";

     var image_area = document.createElement("div");
     image_area.id = state.prefix + "_image_area" + state.iUN;
     image_area.className = state.prefix + "_image_area" + state.iUN + " " +  state.prefix + "_image_area"
     + " image_area " + " " + add_custom_class;

     image_area.addEventListener("click",function(e)
     {
       e.preventDefault();
       if(state.has_callout == "true")
       {
         var callout_fn = state.callout_params[0];
         callout_fn(e,this.id,state.callout_params[1],state.callout_params[2],state.callout_params[3],state.callout_params[4],state.callout_params[5]);
       }//end if
       if(state.stop_event_bubble == "true")
       {
         e.stopPropagation();
       }//if
       console.log("image area click entered");
     });

     state.image_object.id = state.prefix + "_ImgImage" + state.iUN;

     //state.event_ids.push(state.canvas.id);
     state.event_ids = [state.image_object.id];
     state.event_ids.push(image_area.id);

     state.image_object.className = state.prefix + "_ImgImage" + state.iUN + " " + state.prefix + "_ImgImage ImgImage responsive_image " + add_custom_class;

     // if(state.fill_content != ""){state.canvas.innerHTML = state.fill_content;}

     image_area.appendChild(state.image_object);
     bigDaddy.appendChild(image_area);

     // state.context_obj = state.canvas.getContext('2d');
     state.image_object.width = state.canvas_width;
     state.image_object.height = state.canvas_height;

     //set parents inline w,h
     var image_parent = state.image_object.parentNode;
     switch(state.type_str)
     {
       //is set like using this above
       //state.type_str = (state.mode != "default") ? state.canvas_type + "_" + state.mode : state.canvas_type;
       case "image_thumbnail":

       break;

       case "image_profile":
         //canvas_parent.className += (canvas_parent.className.indexOf("d3-profile-rounded") == -1) ? " d3-profile-rounded " : "";
         state.image_object.className += (state.canvas.className.indexOf("d3-profile-rounded") == -1) ? " d3-profile-rounded " : "";
         //console.log(canvas_parent.className);
       break;

       case "image_image":

       break;

       //this is used to show raw image
       //coresponding code : setRawDisplay & probably this entire switch (initially)
       case "profile_raw":
       case "thumbnail_raw":
       case "image_raw":
         // state.type_str = (state.mode != "default") ? state.canvas_type + "_" + state.mode : state.canvas_type;
       break;
     }//end switch

     image_parent.className += (image_parent.className.indexOf(add_parent_class) == -1) ? " " + add_parent_class + " " : "";
     //can_par.style.width = state.canvas_width;
     //can_par.style.height = state.canvas_width;


}//end create_image
