  console.log("prep https running!");

  export const prep_https = function(val)
  {
    let state = this;
    var targetValue = val,
    new_Value = "";
    var current_location = window.location.href;
    current_location = (current_location.indexOf("http://localhost") != -1) ? "local" : "live";
    //only force it if the url isn't from the same origin
    let is_away = (targetValue.includes(window.location.hostname)) ? false : true;

    if(is_away && current_location != "local" && targetValue.indexOf("http") != -1 && targetValue.indexOf("https") == -1 && state.force_https == "true")
    {
      //hack for urls scripted in. - should be saved without a location or protpcol
      // let my_location = window.location.host;
      // new_Value = new_Value.replace("sunzao.us",my_location);
      // new_Value = new_Value.replace("getalight.org",my_location);
      new_Value = targetValue.replace("http","https");
      //new_Value = targetValue.replace("https","http");

    }
    else {
      new_Value = targetValue;
    }
    // else{
    //   new_Value = targetValue;
    //   //hack for urls scripted in. - should be saved without a location or protpcol
    //   let my_location = window.location.host;
    //   new_Value = new_Value.replace("sunzao.us",my_location);
    //   new_Value = new_Value.replace("getalight.org",my_location);
    //   //new_Value = targetValue.replace("https","http");
    //   //new_Value = new_Value.replace("sunzao.us","getalight.org");
    // }
    return new_Value;
  }//prep_https


  export const prep_url = function(u)
  {
    // preps local urls to match the current protocol
    let state = this;
    let my_hostname = window.location.host;
    my_hostname = my_hostname.replace("www.","");
    let my_protocol = window.location.protocol;// http: || https:
    let secure = "https:";
    let unsecure = "http:";

    let url = u;
    if(u == undefined)return u;

    if(url.includes(my_hostname)){

      // if(url.includes(my_protocol)){
      //   return url;
      // }else{
      //   switch (my_protocol) {
      //     case secure:
      //         url.replace(unsecure,secure);
      //       break;
      //     default:
      //       url.replace(secure,unsecure);
      //   }
      //   return url;
      // }
      let url_obj = new URL(url);
      url = url.replace(url_obj.origin,location.origin);
      return url;

    }else{
      return url;
    }

  }//prep_url
