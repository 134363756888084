console.log(`update color running!`);

export const update_color = function()
{
  let state = this;
  state.bg_color = document.querySelector('.color_btn').value;
  console.log("bg color value = ",state.bg_color);
  //state.control_panel();
  //draw it
  state.draw_me();
}//state.update_color
