const aSi = require('./add_slider_input.js');
const rC = require('./reset_canvas.js');
const zM = require('./zoom.js');
const uClr = require('./update_color.js');

  export const run_contents = function(str)
  {
    let state = this;
    let add_slider_input = aSi.add_slider_input.bind(state);
    let reset_canvas = rC.reset_canvas.bind(state);
    let zoom = zM.zoom.bind(state);
    let update_color = uClr.update_color.bind(state);

    switch(str)
    {
      case "IP":
        //image position
        add_slider_input(0);
      break;

      case "IS":
        //image scale
        add_slider_input(1);
      break;

      case "CB":
        //state.canvas border
        add_slider_input(2);
      break;

      case "BS":
        //state.canvas border scale
        add_slider_input(3);
      break;

      case "BC":
        update_color();
      break;

      case "RE":
        reset_canvas();
      break;

        //resize section
      case "ZI":

        zoom("in");

      break;

      case "RI":

        reset_canvas("initial");

      break;

      case "ZO":

        zoom("out");

      break;

    }//end switch

  }//end state.run_contents
