console.log(`reset canvas running!`);

  export const reset_canvas = function(view)
  {
    let state = this;
    let re_view = view || "default";

    /*
    var drawString_ary = state.initial_view.split(",");
    state.scale = drawString_ary[9];
    state.data_h = drawString_ary[10];

    state.img_url = drawString_ary[0];
    state.src_x = drawString_ary[1];
    state.src_y = drawString_ary[2];
    state.img_w = drawString_ary[3];
    state.img_h = drawString_ary[4];
    state.can_x = drawString_ary[5];
    state.can_y = drawString_ary[6];
    state.can_w = drawString_ary[7];
    state.can_h = drawString_ary[8];
    */
    //var drawString_ary = state.initial_view.split(",");
    switch(re_view){

      case "initial":

        state.drawData = "true";
        state.scale = state.initial_view.scale;

        //state.data_h = state.initial_view.data_h;

        state.img_url = unescape(state.initial_view.img_url);
        state.src_x = state.initial_view.src_x;
        state.src_y = state.initial_view.src_y;
        state.img_w = state.initial_view.img_w;
        state.img_h = state.initial_view.img_h;
        state.can_x = state.initial_view.can_x;
        state.can_y = state.initial_view.can_y;
        state.can_w = state.initial_view.can_w;
        state.can_h = state.initial_view.can_h;
        break;

        default:
          state.drawData = "false";
          state.scale = state.default_view.scale;

          //state.data_h = state.default_view.data_h;

          state.img_url = unescape(state.default_view.img_url);
          state.src_x = state.default_view.src_x;
          state.src_y = state.default_view.src_y;
          state.img_w = state.default_view.img_w;
          state.img_h = state.default_view.img_h;
          state.can_x = state.default_view.can_x;
          state.can_y = state.default_view.can_y;
          state.can_w = state.default_view.can_w;
          state.can_h = state.default_view.can_h;
        break;
    }//end switch

      /*
            state.src_x = 0;
            state.src_y = 0;
            state.img_w = 500;
            state.img_h = 500;
            state.can_x = 0;
            state.can_y = 0;
            state.can_w = state.canvas_width;
            state.can_h = state.canvas_width;
            //state.canvas.width = state.canvas.width;
      */


        //state.create_canvas(state.obj_els["edit_home_id"]);
        state.current_state = re_view;//"initial"
        state.control_panel();
        //draw it
        state.draw_me();

  }//end state.reset_canvas
