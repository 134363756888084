    //alert('masterImage running');

    console.log("snakes running");
    /********************************************************************************************************

    //dependencies
    JQuery
    JQueryMobile

    //cors enableing solution
    //https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image

    IMPORTANT!!!
    state.canvas must be inside a flexible element which is set inside a static element.
    the flex element can dynamically add portrait to it

    sample data:
    obj_elements.prev_img = new masterImage({home:'prev_cont',url:targetElement.value,type:"profile"});
    obj_elements.prev_img.setCustomClass("prev_img");

    //this one sets the view for pre-edited images
    if(dpi_obj.extra != undefined && dpi_obj.extra != "")
    {

      obj_elements.prev_img.setView(dpi_obj.extra);

    }else
    {
      //otherwise start from scratch
      obj_elements.prev_img.setRawDisplay();
    }

    obj_elements.prev_img.display();




    *********************************************************************************************************/
    // import {default_dimensions} from './lib/default_dimensions.js';
    // const default_dimensions = require('./lib/default_dimensions.js').default;// works but not in masterImage
    require('./scss/app.d3po_ITK.scss');
    const dD = require('./lib/default_dimensions.js');
    const sV = require('./lib/set_view.js');
    const pHtp = require('./lib/prep_https.js');
    const cCan = require('./lib/create_canvas.js');
    const cImg = require('./lib/create_image.js');
    const dMe = require('./lib/draw_me.js');
    const dImg = require('./lib/draw_image.js');
    const cPan = require('./lib/controls/control_panel.js');


  window.masterImage = function(mstrObj){
      //Image Tool Kit
      //console.log("in the pit");
      //properties
      //required

      var state = {};
      if(mstrObj == undefined){alert("canvas object parameter is not defined."); return;}
      if(mstrObj.home == undefined){alert("canvas object needs \"home:'container id string'\"."); return;}
      state.home = mstrObj.home;

      state.iUN = mstrObj.iUN || Math.round(Math.random() * 10000);//see state.iUN get and set
      state.canvas_type = mstrObj.type || "thumbnail";//profile, raw, banner?
      state.mode = mstrObj.mode || "default";


      //at 100 x100 or 50 x50 the image is adjusted perfectly
      //100 x 80 the image is stretched
      //NOTE image 4:3 aspect ratio multiply by 3/4ths or .75
      state.type_str = (state.mode != "default") ? state.canvas_type + "_" + state.mode : state.canvas_type;

      //used to set the various state.canvas default dimensions (if they arent manually entered)
      state.default_width;
      state.default_height;

      var default_dimensions = dD.default_dimensions.bind(state);

      default_dimensions({state});

      //this takes the objects with or a prefactored width
      state.canvas_width = mstrObj.width || state.default_width;//dictates the resolution
      state.canvas_height = mstrObj.height || state.default_height;


      //HTML generated variable
      //window['CANVAS_IMG_URL'] = "<?php echo JUri::root(); ?>components/com_arc/xfiles/images/";
      state.img_default = window['IMG_URL'] + "flame.png";
      state.auto_validate = "false";

      //properties
      state.canvas = "";
      state.element = mstrObj.element || "canvas";
      state.mini_canvas = "";
      state.context_obj = "";
      state.mini_context = "";
      // var action = mstrObj.action || "";//deprecated
      state.prefix = mstrObj.varName || "masImg";//get set
      state.fill_content = "";
      //console.log(display);
      state.custom_class = "";
      state.add_to_class = "false";
      state.parent_class = "";
      state.custom_id = "";
      state.id_type = "default";
      // state.first_run = "true";//deprecated
      state.sli_ctrl_inputA = "";
      state.sli_ctrl_inputB = "";
      state.mousedown =  false;
      state.touchdown = false;
      // var last_panel_clicked_id = "";//remove//deprecated
      state.last_x = "default";
      state.last_y = "default";
      state.offset_x = 0;
      state.offset_y = 0;
      // var img_label = "default";//deprecated
      state.slide_limit = 500;
      state.current_state = "initial";//other state is modified
      state.initial_view = "";
      state.default_view = {};
      //var display_size = "default";
      state.drawData = "false";
      state.scale = mstrObj.scale || 1;//formerly data_w
      state.data_h = "";
      state.clearHome = "true";
      state.show_adv = "";
      state.bg_color = mstrObj.color || "rgba(255, 0, 0, 0)";
      state.import_data = "false";
      state.match_view = "false";
      //var external_canvas = "false";
      // state.current_display = mstrObj.display ||  state.mode;//look deprecated
      state.stop_event_bubble = "false";
      state.force_https = "true";


      state.img_url = (mstrObj != undefined && mstrObj.url != undefined) ? unescape(mstrObj.url) : state.img_default;
      // state.img_data = "";//deprecated
      state.canvas_data = "";
      state.canvas_json = {};
      state.take_snapshot = false;
      state.snap_data = "";

      //obj_globals
      state.src_x = 0;
      state.src_y = 0;//formerly 5
      state.img_w = 500;
      state.img_h = 500;
      state.can_x = 0;
      state.can_y = 0;
      state.can_w = state.canvas_width;
      state.can_h = state.canvas_height;
      state.init_can_w = state.canvas_width;//this helps to establish a baseline for the zoom feat.
      //state.default_view = [img_url, state.src_x, state.src_y, state.img_w, state.img_h, state.can_x, state.can_y, state.can_w, state.can_h, state.canvas_width, state.canvas_height].join();
      state.default_view = {"img_url":escape(state.img_url),"src_x":state.src_x, "src_y":state.src_y, "img_w":state.img_w, "img_h":state.img_h, "can_x":state.can_x, "can_y":state.can_y, "can_w":state.can_w, "can_h":state.can_h,"canvas_width":state.canvas_width,"canvas_height":state.canvas_height,"bg_color":state.bg_color,"type":state.canvas_type};
      state.target_validity;

      //NOTE I don't need this. won't be saving this to local storage #remove
      /*try{
      if(localStorage != undefined && localStorage.canvas_tutorial != undefined && localStorage.canvas_tutorial != "")
      {
        var local_str = localStorage.canvas_tutorial;
        var local_ary = local_str.split(",");
        state.img_url = local_ary[0];
        state.src_x = local_ary[1];
        state.src_y = local_ary[2];
        state.img_w = local_ary[3];
        state.img_h = local_ary[4];
        state.can_x = local_ary[5];
        state.can_y = local_ary[6];
        state.can_w = local_ary[7];
        state.can_h = local_ary[8];
      }//end if
      }catch(e){
        console.log("nope. reload failed.")
      }*/

      state.obj_els = {};
      state.event_ids = [];
      state.has_callout = "false";
      state.callout_params = ["","","","","","","",""];
      state.has_load_callout = "false";
      state.load_callout_params = ["","","","","","","",""];
      state.has_go_callout = "false";
      state.go_callout_params = ["","","","","","","",""];
      state.has_can_callout = "false";
      state.can_callout_params = ["","","","","","","",""];
      state.has_err_callout = "false";
      state.err_callout_params = ["","","","","","","",""];


      //methods
      this.setContent = function(sC){state.fill_content = sC;}//
      this.get_event_ids = function(){return state.event_ids;}
      this.setCustomClass = function(clsStr,addPar){state.custom_class = clsStr; state.add_to_class = addPar || true;/*addPar is nothing yet*/}
      this.setParentClass = function(clsStr){state.parent_class = clsStr;}
      this.setCustomId = function(cId){state.custom_id = cId; state.id_type = "custom";}
      this.allow_http = function(str){state.force_https = "false";}
      this.getImgData = function()
      {
        return {
          "url":escape(state.img_url),
          "data_type":"canvas_data",
          "canvas_data":state.canvas_data,
          "canvas_json":state.canvas_json
        };
      }//getImgData

      this.setSnapData = function () {
        state.take_snapshot = true;// may not need this - maybe for downloads?
      }//setSnapData

      this.getSnapData = async function() {
        return make_snapData()
        .then(function (result) {
          return state.snap_data = result;
        })
        .catch(function (err) {
          console.log(`tainted canvas issue ${err}`)
          return state.snap_data;
        });

      }//getSnapData

      var make_snapData = async function () {
        let snap_shot = state.canvas.toDataURL();
        return snap_shot;
      }//make_snapData


      this.setUrl = function(str){state.img_url = unescape(str)};
      this.setRawDisplay = function(){state.mode = "raw"; state.type_str = state.canvas_type + "_" + state.mode;}
      this.setFitDisplay = function(){state.mode = "fit"; state.type_str = state.canvas_type + "_" + state.mode;}
      this.clearHome = function(str){state.clearHome = str};
      this.setDefaultImg = function(str){state.img_default = str;};
      this.autoValidate = function(){state.auto_validate = "true";};
      this.advCtrls = function(){state.show_adv = " state.show_adv ";};
      this.setColor = function(col){state.bg_color = col;};
      this.matchView = function(){state.match_view = "true";};
      this.setScale = function(nbr){state.scale = nbr;};
      this.setWidth = function(nbr){state.canvas_width = nbr;};
      this.setMode = function(str){state.mode = str;};
      this.setHeight = function(nbr){state.canvas_height = nbr;};
      this.setImageElement = function(){state.element = "image";};

      this.redraw = async function()
      {
        //redraw doesn't seem to be working at all
        //state.canvas.clearRect(0,0,state.canvas.width,state.canvas.height);
        //state.context_obj.setTransform(1, 0, 0, 1, 0, 0);
        state.context_obj.clearRect(0,0,state.canvas_width,state.canvas_height);
        await state.draw_me()
        .catch(function (err) {
          console.log(`redraw error ${err}`)
        });
        return true;
      };

      let setView = sV.setView.bind(state);

      this.setView = function(drawString)
      {
        setView(drawString);
      }//end setView

      state.prep_https = pHtp.prep_https.bind(state);
      state.prep_url = pHtp.prep_url.bind(state);

      this.setCallout = function()
      {
        /*sample use
        if(state.has_callout == "true")
        {
            var callout_fn = state.callout_params[0];
            callout_fn(state.callout_params[1],state.callout_params[2],state.callout_params[3],state.callout_params[4],state.callout_params[5]);

        }//end if
        */
        state.has_callout  = (arguments.length != 0) ? "true" : "false";
        for(var i = 0; i < arguments.length; i++)
        {
          state.callout_params[i] = arguments[i];

        }//end for

      }//end setCallout

      this.setLoadCallout = function()
      {
        /*sample use
        if(state.has_load_callout == "true")
        {
            var load_callout_fn = state.load_callout_params[0];
            load_callout_fn(state.load_callout_params[1],state.load_callout_params[2],state.load_callout_params[3],state.load_callout_params[4],state.load_callout_params[5]);

        }//end if
        */
        state.has_load_callout  = (arguments.length != 0) ? "true" : "false";
        for(var i = 0; i < arguments.length; i++)
        {
          state.load_callout_params[i] = arguments[i];

        }//end for

      }//end setLoadCallout

      this.setGoCallout = function()
      {
            /*sample use
          if(state.has_go_callout == "true")
          {
              var go_callout_fn = state.go_callout_params[0];
              go_callout_fn(state.go_callout_params[1],state.go_callout_params[2],state.go_callout_params[3],state.go_callout_params[4],state.go_callout_params[5]);

          }//end if
          */
          state.has_go_callout = (arguments.length != 0) ? "true" : "false";
          for(var i = 0; i < arguments.length; i++)
          {
            state.go_callout_params[i] = arguments[i];

          }//end for

      }//end setGoCallout

      this.setCancelCallout = function()
      {
            /*sample use
          if(state.has_can_callout == "true")
          {
              var can_callout_fn = state.go_callout_params[0];
              can_callout_fn(state.can_callout_params[1],state.can_callout_params[2],state.can_callout_params[3],state.can_callout_params[4],state.can_callout_params[5]);

          }//end if
          */
          state.has_can_callout = (arguments.length != 0) ? "true" : "false";
          for(var i = 0; i < arguments.length; i++)
          {
            state.can_callout_params[i] = arguments[i];

          }//end for

      }//end setCancelCallout

      this.setErrorCallout = function()
      {
            /*sample use
          if(state.has_err_callout == "true")
          {
              var err_callout_fn = state.err_callout_params[0];
              err_callout_fn(state.err_callout_params[1],state.err_callout_params[2],state.err_callout_params[3],state.err_callout_params[4],state.err_callout_params[5]);

          }//end if
          */
          state.has_err_callout = (arguments.length != 0) ? "true" : "false";
          for(var i = 0; i < arguments.length; i++)
          {
            state.err_callout_params[i] = arguments[i];

          }//end for

      }//end setErrorCallout

      this.stop_bubble = function()
      {

        state.stop_event_bubble = "true";

      }//end stop_bubble


      this.runDataCheck = function(){

        return state.target_validity;

      }//end runDataCheck


      //i don't need external data because the edit mode doesn't actually change anything it just preps data
      //for use elsewhere
      //this.setExternalCanvas = function(xId){external_canvas = "true"; external_id = xId;}//can be a className

      state.image_object = new Image();
      //state.image_object.setAttribute('crossOrigin', 'anonymous');


      state.create_canvas = cCan.create_canvas.bind(state);

      state.create_image = cImg.create_image.bind(state);

      state.draw_me = dMe.draw_me.bind(state);

      state.draw_image = dImg.draw_image.bind(state);

      var canvas_editor = function()
      {



      }//end canvas_editor


      var display = async function(){
        switch(state.mode)
        {
          case "edit":
            //prep edit elements that add state.canvas
            state.control_panel();
            //draw it
            await state.draw_me()
            .catch(function (err) {
              console.log(`display error ${err}`)
            });
          break;
          case "image":
            state.create_image(state.home);
            await state.draw_image()
            .catch(function (err) {
              console.log(`display error ${err}`)
            });
          break;

          default:
            state.create_canvas(state.home);
            await state.draw_me()
            .catch(function (err) {
              console.log(`display error ${err}`)
            });
          break;

        }//end switch
        return true;
      }//end display

      this.display = async function(){
        await display()
        .catch(function (err) {
          console.log(`display error ${err}`)
        });
        return true;
      }


      /********************************   SCRAP SECTION BELOW   ****************************************/

      //NOTE i don't need this panel array - i will need a btn array in its place.

      //state.ctrl_ary = ctrl_ary;
      //state.size_ary = size_ary;
      //state.size_ary.reverse();

      state.control_panel = cPan.control_panel.bind(state);

      //state.ctrl_txt = cPan.ctrl_txt

      // state.zoom
      // deprecated
      // var center_me = function()
      // {
      //
      //   state.can_x = (state.canvas.width - state.can_w) / 2;
      //   state.can_y = (state.canvas.height - state.can_h) / 2;
      //
      //   state.current_state = "modified";
      //   state.draw_me();
      //
      //
      // }//end center_me



      // state.run_contents = run_contents;

      // state.reset_canvas

      // update_color

      //html5 slider research
      //https://codepen.io/collection/DgYaMj/2/
      //http://thenewcode.com/757/Playing-With-The-HTML5-range-Slider-Input
      //http://danielstern.ca/range.css/#/
      //http://www.cssportal.com/style-input-range/


      //state.add_slider_input = add_slider_input;

      // state.update_slide_limits


      // state.getTouchPos = getTouchPos;

      //state.getMousePos = getMousePos;

    	// var canvas_mouse_events = canvas_mouse_events;

      //get_touch_offset

        // state.image_update = image_update;

      // state.slide_data = slide_data;


    /*

      //this.state.draw_me = function(){ state.draw_me();  };
    */


  }//end masterImage

//use this area to run scripts
/*
window.onload = function(){
  //state.img_url = "https://cgi.chevrolet.com/mmgprod-us/dynres/prove/image.gen?i=2017/1YZ07/1YZ07__2LZ/GBA_gmds1.jpg&v=deg04&std=true&country=US&send404=true";

  state.img_url = "https://lh5.googleusercontent.com/O6xOEYV1QnMBTShuvZpvmv-giqtXeXKioZcrErPxjKKVF4xrbME87iQZpTSa6tUBEOTwDsz3dIE=w1200-h630-p";

  //state.img_url = "https://static6.businessinsider.com/image/55f041449dd7cc17008b8fd4-3264-2448/img_2686%20(1).jpg";

 // state.img_url = "https://static.stereogum.com/uploads/2013/08/lauryn-hill.jpg";

  var edit_img = new masterImage({'home':'fish_content','url':state.img_url,'type':"profile",'mode':"edit"});
  edit_img.setCustomClass("edit_img");
  edit_img.display();
}//end onload

//NOTE i may not need this. #remove?
function set_dimensions()
{
  //console.log(document.body.clientHeight);
  var c_width = document.body.clientWidth;
  console.log(document.body.clientWidth);

  var canvas_el = document.getElementById("tutorial");
  var ctrl_el = document.getElementById("info_cont");
  //
  var dyn_var = (document.body.clientWidth > 499) ? 2.7 : 1.2;
  var w_calc = parseInt(c_width) / dyn_var;
  //console.log(w_calc/10);//
  canvas_el.style.height = w_calc + "px";

  //ctrl_el.style.height = w_calc + "px";
}//end set_dimensions

//set_dimensions();
*/
