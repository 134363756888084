  console.log(`zoom running!`);

  export const zoom = function(dir)
  {
    //console.log("zoom running");
    let state = this;
    switch(dir)
    {
      case "in":

        //imposes a zoom limit
        if(state.can_w < state.init_can_w * 8)
        {

          //state.img_w = state.img_w / 2;
          state.can_w = state.can_w * 1.1;
          state.can_h = state.can_h * 1.1;

          //center formula
          state.can_x = (state.canvas.width - state.can_w) / 2;
          state.can_y = (state.canvas.height - state.can_h) / 2;

          state.current_state = "modified";
          state.draw_me();

        }//if limit

      break;

      case "out":

        //imposes a zoom limit
        if(state.can_w > state.init_can_w / 5)
        {
             //state.img_w = state.img_w / 2;
            state.can_w = state.can_w / 1.1;
            state.can_h = state.can_h / 1.1;

            //center formula
            state.can_x = (state.canvas.width - state.can_w) / 2;//
            state.can_y = (state.canvas.height - state.can_h) / 2;

            //state.current_state = "modified";
            state.draw_me();
        }//end limit
      break;

    }//end zoom

  }//end zoom
