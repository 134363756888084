console.log(`arrays in control running!`);

  export const ctrl_ary = [
  {
  "label":"POSIION",
  "contents":"IP",
  "title":"Image Position",
  "class":" move_btn d3-btn d3-ui d3-icon-move",
  "type":"button"
  },
  {
  "label":"SCALE",
  "contents":"IS",
  "title":"Image Scale",
  "class":" scale_btn d3-btn d3-ui d3-icon-scale ",
  "type":"button"
  },
  {
  "label":"BORDERS",
  "contents":"CB",
  "title":"canvas Borders",
  "class":" border_btn d3-btn d3-ui d3-icon-border ",
  "type":"button"
  },
  {
  "label":"BORDER SCALE",
  "contents":"BS",
  "title":"canvas Border scale",
  "class":" border_scale_btn d3-btn d3-ui d3-icon-border_scale ",
  "type":"button"
  },
  {
  "label":"BACKGROUND COLOR",
  "contents":"BC",
  "title":"Background Color",
  "class":" color_btn d3-btn d3-ui d3-icon-colors ",
  "type":"input"
  },
  {
  "label":"RESET",
  "contents":"RE",
  "title":"original image view",
  "class":" reset_btn d3-btn d3-ui d3-icon-reset ",
  "type":"button"
  }

  ];//end state.ctrl_ary

  export const size_ary = [
  {
  "label":"zoom in",
  "contents":"ZI",
  "id":"zoom_in",
  "class":"zoom_in zoom_ctrls d3-ui d3-icon-zoom_in ",
  "title":"zoom in closer for more detail"
  },
  {
  "label":"reset to saved",
  "contents":"RI",
  "id":"reset_image",
  "class":"reset_image zoom_ctrls  d3-ui d3-icon-reset ",/*set in setView*/
  "title":"reset to saved"
  },
  {
  "label":"zoom out",
  "contents":"ZO",
  "id":"zoom_out",
  "class":"zoom_out zoom_ctrls d3-ui d3-icon-zoom_out ",
  "title":"zoom out for a wider view"
  }
  ];//end state.size_ary
