console.log(`draw me is running!`);
const uSL = require('./update_slide_limits.js');

export const draw_me = function() {

   //console.log("draw running");
   let state = this;

   return new Promise((resolve, reject) => {
     let update_slide_limits = uSL.update_slide_limits.bind(state);
      //clear the state.canvas
      //state.canvas.width = state.canvas.width;
      var add_custom_class = (state.custom_class != "") ? state.custom_class  : "";
      let is_fit = (state.mode == "fit" || state.canvas_type == "fit") ? "true" : "false";

          if (state.canvas.getContext) {

            state.image_object.onload=function(){

              let init_state = (state.current_state == "initial" || state.current_state == "default") ? "true" : "false";
              //

              if(init_state == "true" && state.drawData != "true")
              {

                  //study: this is the section to get the
                  //images initial settings
                  state.img_w = state.image_object.naturalWidth;
                  state.img_h = state.image_object.naturalHeight;

                  //for this to work without stretching
                  //the last parameters have to match
                  //the image parameters

                  //this calculates larger image issues



                  if(state.img_w > state.img_h || is_fit == "true"){
                    //landscape view
                    //uses the smaller of the 2 widths
                    state.can_w = (state.img_w > state.canvas.width) ?
                    state.canvas.width : state.img_w;

                    //refactor if image is landscape and state.canvas isn't landscape

                      //og calc: state.img_h / (state.img_w / state.canvas.width)
                      //refactor image
                      if( is_fit != "true"){
                        state.can_h = (state.img_w > state.canvas.width) ? state.canvas.width  / (state.img_w / state.img_h) : state.img_w / (state.img_w / state.img_h) ;
                      }else{
                        state.can_h = state.can_w / (state.img_w / state.img_h);

                      }//end if

                    state.slide_limit = (is_fit == "true" && state.can_h > state.can_w) ? state.img_h : state.img_w;
                  }else{
                    //portrait view
                    //uses the smaller of the 2 widths
                    state.can_h = (state.img_h > state.canvas.height) ?
                    state.canvas.height : state.img_h;
                    //state.can_w = state.img_w / (state.img_h / state.canvas.height);
                    state.can_w = (state.img_h > state.canvas.height) ? state.canvas.height * (state.img_w / state.img_h) :  state.img_h * (state.img_w / state.img_h);
                    state.slide_limit = state.img_h;//why not state.can_h
                  }//end else calculations

                  //console.log(state.image_object.naturalWidth);
                  //console.log(state.image_object.naturalHeight);
                  state.init_can_w = state.can_w;
                  //center script
                  state.can_x = (is_fit == "true") ? 0 : (state.canvas.width - state.can_w) / 2;
                  state.can_y = (is_fit == "true") ? 0 : (state.canvas.height - state.can_h) / 2;

                  //state.initial_view = [state.img_url, state.src_x, state.src_y, state.img_w, state.img_h, state.can_x, state.can_y, state.can_w, state.can_h, state.canvas_width, state.canvas_height].join();
                  if(state.current_state == "initial"){
                    state.initial_view = {
                      "img_url":escape(state.img_url),
                      "src_x":state.src_x, "src_y":state.src_y,
                      "img_w":state.img_w, "img_h":state.img_h,
                      "can_x":state.can_x, "can_y":state.can_y,
                      "can_w":state.can_w, "can_h":state.can_h,
                      "canvas_width":state.canvas_width,"canvas_height":state.canvas_height,
                      "bg_color":state.bg_color
                    };
                  }//end if

                  state.current_state = "modified";
                  state.drawData = "true";

              }//end if


              //needs this to keep drawing movements smooth
              state.canvas.width = (is_fit != "true") ? state.canvas_width : (state.img_w > state.canvas_width) ? state.canvas_width : state.img_w; // whichever is smaller
              state.canvas.height = (is_fit == "true") ? state.canvas.width / (state.img_w / state.img_h)  : state.canvas_height;

              if(state.drawData == "true")
              {

                var scale_factor = state.scale;//state.canvas_width / state.scale

                //i don't think it needs both w & h.  It scales by ratio 1:1 width and height.
                //if the container is different it may throw off the scaling
                //state.context_obj.scale(scale_factor,scale_factor);//100
                //console.log("scale factor = ",scale_factor);

              }//end if
              //state.context_obj.fillStyle = state.bg_color;
              //state.context_obj.fillRect(0, 0, state.can_w, state.can_h);//formerly : state.canvas_width, state.canvas_height
              if(state.mode == "center"){
                //state.src_x = state.can_w / 2 - state.img_w / 2;
                //state.src_y = state.can_h / 2 - state.img_h / 2;
                if(state.img_w > state.canvas_width || state.img_h > state.canvas_height || state.scale > 1)
                {
                  let old_w = state.can_w;
                  let old_h = state.can_h;
                  state.can_w = state.can_w * state.scale;
                  state.can_h = state.can_h * state.scale;
                  state.can_x = (state.can_w - state.canvas_width) / -2;
                  state.can_y = (state.can_h - state.canvas_height) / -2;
                }else {
                  let old_w = state.can_w;
                  let old_h = state.can_h;
                  state.can_x = (state.canvas_width - state.img_w) / 2;
                  state.can_y = (state.canvas_height - state.img_h) / 2;
                }

              }//if

              state.context_obj.drawImage(state.image_object, state.src_x, state.src_y, state.img_w, state.img_h, state.can_x, state.can_y, state.can_w, state.can_h);
              //console.log("state.image_object",state.image_object);//try here

              //prep the output
              //state.img_data = state.canvas.toDataURL();
              state.canvas_json = JSON.stringify({
                "img_url":escape(state.img_url),
                "src_x":state.src_x,
                "src_y":state.src_y,
                "img_w":state.img_w,
                "img_h":state.img_h,
                "can_x":state.can_x,
                "can_y":state.can_y,
                "can_w":state.can_w,
                "can_h":state.can_h,
                "canvas_width":state.canvas_width,
                "canvas_height": state.canvas_height,
                "bg_color":state.bg_color,
                "type":state.canvas_type
              });
              state.canvas_data = [escape(state.img_url), state.src_x, state.src_y, state.img_w, state.img_h, state.can_x, state.can_y, state.can_w, state.can_h, state.canvas_width, state.canvas_height,/*formerly bg_color*/, state.canvas_type].join();

              /*
              if(state.mode != "edit")
              {
                state.image_object.id = state.prefix + "_imgDisp" + state.iUN;
                state.image_object.className = state.prefix + "_imgDisp" + state.iUN + " " + state.prefix + "_imgDisp " + " imgDisp " + add_custom_class;
                state.image_object.src = state.img_data;

                var my_home = state.canvas.parentNode;
                my_home.replaceChild(state.image_object,state.canvas);
                state.event_ids = [state.image_object.id];
                //switch the state.canvas for the image

              }
              */

              if(state.mode == "edit")
              {
                //create a mini preview
                var mini_scale_factor = .5;
                state.mini_canvas.width = state.canvas.width * mini_scale_factor;//formerly state.canvas_width
                state.mini_canvas.height = state.canvas.height * mini_scale_factor;//formerly state.canvas_height

                //state.mini_context.fillStyle = state.bg_color;
                //state.mini_context.fillRect(0, 0, state.can_w, state.can_h);//formerly : state.canvas_width, state.canvas_height
                state.mini_context.scale(mini_scale_factor,mini_scale_factor);

                state.mini_context.drawImage(state.image_object, state.src_x, state.src_y, state.img_w, state.img_h, state.can_x, state.can_y, state.can_w, state.can_h);

              }//end if

              state.target_validity = "valid";

              //console.log("running load section");
              if(state.has_load_callout == "true")
              {
                //console.log("running load section");
                  var load_callout_fn = state.load_callout_params[0];
                  load_callout_fn(state.load_callout_params[1],state.load_callout_params[2],state.load_callout_params[3],state.load_callout_params[4],state.load_callout_params[5]);

              }//end if

              //  state.canvas.checkValidity();
              //console.log("img loaded");
              let class_str = ".sli_ctrl_inputA_" + state.iUN
              if(state.mode == "edit" && document.querySelector(class_str))
              {

                update_slide_limits("sli_ctrl_inputA",state.image_object.naturalWidth);
                update_slide_limits("sli_ctrl_inputB",state.image_object.naturalWidth);
              }

              resolve("image loaded");

            }//end onload//

            state.image_object.onerror = function(errorMsg, url, lineNumber, column, errorObj)
            {
              //state.canvas.checkValidity();
              state.target_validity = "invalid";

              if(state.auto_validate == "true" && state.target_validity == "invalid")
              {
                console.log("registered img error");
                state.img_url = state.img_default;
                state.auto_validate = "finished";
                state.draw_me()
                .then(function () {
                  resolve();
                })
                .catch(function() {
                  reject();
                });

              }else if(state.has_err_callout == "true")
              {
                  var err_callout_fn = state.err_callout_params[0];
                  err_callout_fn(state.err_callout_params[1],state.err_callout_params[2],state.err_callout_params[3],state.err_callout_params[4],state.err_callout_params[5]);

                  reject();
              }//end if state.has_err_callout
              else{
                reject();
              }


              // return "image failed";
            }//end onerror


            let el_url = (state.img_url != "") ? state.img_url : state.img_default;
            state.image_object.src = state.prep_https(state.prep_url(unescape(state.img_url)));
            console.log(`img obj = ${state.image_object}`);
            //console.log("state.image_object",state.image_object);//nothing to run yet here so its ""
            //var dataURL = state.canvas.toDataURL("image/png");
            //console.log("state.image_object height = ",state.image_object.height);

          }//end if
      });//promise

    }//end draw_me
