  export const slide_data = function(ltr,nbr,obj)
  {
    //span_set2 view_span span3 view_span3
    let state = this;
    var slide_ltr = ltr;
    var nbr = nbr;
    var val = (obj != undefined && obj.value != undefined) ? obj.value : "";
    var val_oper = (obj != undefined && obj.val_oper != undefined) ? obj.val_oper : "get_value";

    var slide_id = ltr+nbr;
    var span_id_str = "span" + slide_id;
    var targetSpan = document.getElementById(span_id_str);

    if(val != "" && val_oper == "add" || val_oper == "both"){
      //A covers x and width
      //B covers y and height
      switch(slide_id)
      {
          case "A0":
              state.src_x = val;
              //targetSpan.innerText = val;
          break;
          case "B0":
              state.src_y = val;
              //targetSpan.innerText = val;
          break;

          case "A1":
              state.img_w = val;
              //targetSpan.innerText = val;
          break;
          case "B1":
              state.img_h = val;
              //targetSpan.innerText = val;
          break;

          case "A2":
              state.can_x = val;
              //targetSpan.innerText = val;
          break;
          case "B2":
              state.can_y = val;
              //targetSpan.innerText = val;
          break;

          case "A3":
              state.can_w = val;
              //targetSpan.innerText = val;
          break;
          case "B3":
              state.can_h = val;
              //targetSpan.innerText = val;
          break;
      }//end switch
    }//end if

    if(val_oper == "get_value" || val_oper == "both"){
      switch(slide_id)
      {
          case "A0":
            return state.src_x;
          break;
          case "B0":
            return state.src_y;
          break;
          case "A1":
              return state.img_w;
          break;
          case "B1":
              return state.img_h;
          break;
          case "A2":
            return state.can_x;
          break;
          case "B2":
            return state.can_y;
          break;

          case "A3":
            return state.can_w;
          break;
          case "B3":
            return state.can_h;
          break;
      }//end switch
    }//end if

  }//end slide_data
