console.log(`draw image is running!`);

export const draw_image = function() {

   //console.log("draw running");
   let state = this;

   return new Promise((resolve, reject) => {
      //clear the state.canvas
      //state.canvas.width = state.canvas.width;
      var add_custom_class = (state.custom_class != "") ? state.custom_class  : "";

            state.image_object.onload=function(){

              let init_state = (state.current_state == "initial" || state.current_state == "default") ? "true" : "false";

              state.target_validity = "valid";

              //console.log("running load section");
              if(state.has_load_callout == "true")
              {
                //console.log("running load section");
                  var load_callout_fn = state.load_callout_params[0];
                  load_callout_fn(state.load_callout_params[1],state.load_callout_params[2],state.load_callout_params[3],state.load_callout_params[4],state.load_callout_params[5]);

              }//end if

              //  state.canvas.checkValidity();
              //console.log("img loaded");

              resolve("image loaded");

            }//end onload//

            state.image_object.onerror = function(errorMsg, url, lineNumber, column, errorObj)
            {
              //state.canvas.checkValidity();
              state.target_validity = "invalid";

              if(state.auto_validate == "true" && state.target_validity == "invalid")
              {
                console.log("registered img error");
                state.img_url = state.img_default;
                state.auto_validate = "finished";
                state.draw_image()
                .then(function () {
                  resolve();
                })
                .catch(function() {
                  reject();
                });

              }else if(state.has_err_callout == "true")
              {
                  var err_callout_fn = state.err_callout_params[0];
                  err_callout_fn(state.err_callout_params[1],state.err_callout_params[2],state.err_callout_params[3],state.err_callout_params[4],state.err_callout_params[5]);

                  reject();
              }//end if state.has_err_callout
              else{
                reject();
              }


              // return "image failed";
            }//end onerror


            let el_url = (state.img_url != "") ? state.img_url : state.img_default;
            state.image_object.src = state.prep_https(state.prep_url(unescape(state.img_url)));
            console.log(`img obj = ${state.image_object}`);

      });//promise

    }//end draw_image
