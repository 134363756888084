console.log(`get pointer position running!`);

export const getMousePos = function(e) {

  let state = this;
  //this section returns the mouse position minus the
  //state.canvas' top or left window position. "rect"

  var canvas_el = document.getElementById("tutorial");
  var rect = state.canvas.getBoundingClientRect();

  //console.log("rect.left" + rect.left);

  return {
    x: (e.clientX - rect.left) ,
    y: (e.clientY - rect.top)
  };

}//- canvas_size/2  - canvas_size/2

export const getTouchPos = function(e) {
  let state = this;
  var rect = state.canvas.getBoundingClientRect();
  //alert("touch x = " + e.touches[0].clientX);
  return {
    x: e.touches[0].clientX - rect.left,
    y: e.touches[0].clientY - rect.top
  };
}// getTouchPos

export const get_touch_offset = function(e)
{
    let state = this;
    var d = new Date();
          //console.log("onmousedown state.mousedown = ",mousedown);
          //console.log("time = ",d.toLocaleString());
          var pos = getTouchPos(e);
          var pos_x = pos.x;
          var pos_y = pos.y;

          var act_x = (pos_x * -1);
          var act_y = (pos_y * -1);

          state.offset_x = act_x - state.src_x;

          state.offset_y = act_y - state.src_y;
          //alert(" state.offset_x & y = " + state.offset_x + "\n " + state.offset_y);

  }//end get touch offset
