console.log();
const gPP = require('./get_pointer_position.js');

export const canvas_mouse_events = function(aVar,recursive_state)
{

  let state = (this != undefined) ? this : recursive_state;// fix for recursion issue below
  let getMousePos = gPP.getMousePos.bind(state);

  if(this == undefined){
    let stop_the_press = true;
  }

  //this function adds and removes events that affects the state.canvas
  //once you are outside of the state.canvas and you let the mouse up
  //this is designed to clear these mouse events from the memory so
  //no event occurs again until you are "mouseover"'d the canvans where
  //which will set up these events again.
  var action = aVar || "remove";
  // var canvas_id = cId;//deprecated
  // var canvas_el = document.getElementById(cId);//deprecated

  if(action == "set"){

        state.canvas.onmousedown = function(e){
          state.mousedown = true;
          e.preventDefault();
          var d = new Date();
          //console.log("onmousedown state.mousedown = ",mousedown);
          //console.log("time = ",d.toLocaleString());
          var pos = getMousePos(e)
          var pos_x = pos.x;
          var pos_y = pos.y;
          //x is dealing with different values so
          //the math is addition not subtraction

          //this offset number has to be
          //permanent not recalculating so it
          //doesn't migrate to another position

          //pos_x - state.src_x; & pos_y - state.src_y; produced errant results
          //pos_x/y is always negative - you cant click on other
          //side of origin - so to properly calculate the offset
          //change mouse position read out from the positive int
          //representing the amount of state.canvas points from the
          //origin to a more accurate negative number representing
          //the actual state.canvas position
          var act_x = (pos_x * -1);
          var act_y = (pos_y * -1);

          state.offset_x = act_x - state.src_x;

          state.offset_y = act_y - state.src_y;

          canvas_mouse_events("set",state);
        }//end onmousedown


        window.onmouseup = function()
        {
          state.mousedown = false;

        }//end onmousedown

        window.ontouchend = function()
        {
          state.touchdown = false;

        }//end ontouchend


  }else
  {
        state.mousedown = false;
        state.canvas.onmousedown = "";
        state.canvas.onmousemove = "";

        state.touchdown = false;//
        state.canvas.ontouchstart = "";
        state.canvas.ontouchmove = "";

  }//end else
}//end canvas_mouse_events
